/** @jsx jsx */
import { jsx } from "theme-ui";
import { Flex } from "@theme-ui/components";
import { renderLineBreak } from "../helpers";
import Container from "../components/container";
import Column from "../components/column";
import Row from "../components/row";
import Text from "../components/text";
import PageLink from "./page-link";

const AlertModule = ({
  bladePatternData = {},
  titleAlignament = "left",
  titleTextType = "h4",
  containerStyle = {},
}) => {
  const { ctaTitle, ctaDestination, title } = bladePatternData;
  const titleStyles = { textAlign: titleAlignament, color: "white", py:"6px" };

  if (!bladePatternData) {
    return null;
  }

  return (
    <Container
      customStyle={{ py: theme => theme.spacing.vertical.xs, maxWidth: "100%", margin: 0 }}
      sx={containerStyle}
    >
      <Row>
        <Column>
          {ctaTitle && ctaDestination ? (
            <PageLink link={ctaDestination} type={"internal"} customStyle={titleStyles}>
              <span sx={{fontFamily: "Helvetica Neue, sans-serif", fontSize:15}}>
                {renderLineBreak(ctaTitle)}
              </span>
            </PageLink>
          ) : (
            title ? (
              <span sx={{fontFamily: "Helvetica Neue, sans-serif", fontSize:15}}>
                {renderLineBreak(title)}
              </span>
            ) : null
          )}
        </Column>
      </Row>
    </Container>
  );
};

export default AlertModule;
